
import {Grid, Typography} from "@mui/material";



const Missing = ({children}) => {
    return (
        <>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        position: 'absolute',
                        top: 75,
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    <Grid item>
                        <Typography variant="h3" align="center">Missing Page</Typography>
                    </Grid>
                </Grid>
        </>
    );
};

export default Missing;