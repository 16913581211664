import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import FileUpload from "../FileUpload";
import { Table, TableBody, TableCell, TableRow} from "@mui/material";
import OneTextInput from "./OneTextInput";
import {RequestContext} from "../../App";



const ChatDocMainComp = forwardRef(({senden,setFormData, inputName, setAdditionalInfo}, ref) => {
    const requestCon = useContext(RequestContext)
    const [file, setFile] = useState(null);


        const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo: buildMsgInfo
    }))

    const buildAddInfo =()=>{

        requestCon["request"] = {
            "Text": inputRef?.current?.getValue(),
            "Nachfrage": false
        }
        return({
            "Text": inputRef?.current?.getValue(),
            "Nachfrage": false
        })
    }

    const buildMsgInfo=()=>{
            requestCon["messages"] = []
        return([])
    }
        return (
            <div>

                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ width:'15%'}} ><FileUpload text={"Dokument hochladen"} acceptType={".pdf"} setFile={setFile} setFormData={setFormData} idText={"docs"} /></TableCell>
                            <TableCell><OneTextInput senden={senden} ref={inputRef}/></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </div>
        );
})

export default ChatDocMainComp;