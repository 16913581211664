import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import OneTextInput from "./OneTextInput";
import {Box, MenuItem, Select, TextField} from "@mui/material";
import TooltipWrapper from "../TooltipWrapper";

const ZusammenfassungMainComp = forwardRef(({inputName, senden, setAdditionalInfo}, ref) => {
    const [lengthTyp, setLengthTyp] = useState("Sätze")
    const [length, setLength] = useState(5)
    const [info, setInfo] = useState({})

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo: buildMsgInfo
    }))
    const buildMsgInfo=()=>{
        return([])
    }

    const buildAddInfo = () => {
        return ({
            "Text": inputRef?.current?.getValue(),
            "Limit": length + " " + lengthTyp
        })
    }

    const handleLengthtypChange = (e) => {
        setLengthTyp(e.target.value)

    }
    const handleLengthChange = (e) => {
        setLength(e.target.value)
    }



    return (
        <div>

                <OneTextInput ref={inputRef} inputName={inputName} senden={senden}>
                    <Box display={"flex"} flexDirection={"row-reverse"}>
                        <TooltipWrapper placement={"top"}
                                        title={"Sprachmodelle haben oft Probleme mit Längeneinschätzungen. Es kann sein, dass die Länge nicht richtig generiert wird."}>
                            <Box display={"flex"} flexDirection={"row-reverse"}>
                                <Select style={{minWidth: 120}}
                                        sx={{
                                            mb: 1
                                        }}
                                        labelId="lengthSelect-label"
                                        id="lenghtSelect"
                                        value={lengthTyp}
                                        onChange={(e) => handleLengthtypChange(e)}

                                >
                                    {['Sätze', 'Wörter', 'Zeichen'].map((text, index) => (
                                        <MenuItem value={text}> {text}</MenuItem>
                                    ))}
                                </Select>
                                <TextField sx={{
                                    maxWidth: 200
                                }}
                                           label="Länge"
                                           defaultValue={5}
                                           fullWidth type={"number"}
                                           onChange={(e) => handleLengthChange(e)}/>
                            </Box>
                        </TooltipWrapper>
                    </Box>
                </OneTextInput>
        </div>
    );
})

export default ZusammenfassungMainComp;