import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import OneTextInput from "./OneTextInput";
import {Box, MenuItem, Select, TextField} from "@mui/material";
import {RequestContext} from "../../App";

const EinfacheEingabeMainComp = forwardRef(({inputName, senden, setAdditionalInfo}, ref) => {
    const [info, setInfo] = useState({})
    const [text, setText] = useState("")

    const requestCon = useContext(RequestContext)

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo: buildMsgInfo
    }))

    const buildMsgInfo=()=>{
        requestCon["messages"] = []
        return([])
    }


    //useEffect(() => {
    //    setAdditionalInfo(buildAddInfo())
    //    console.log(info)
    //}, [text]);



    const buildAddInfo =()=>{
        return({
            "Text": inputRef?.current?.getValue()
        })
    }

    return (
        <div>

                <OneTextInput ref={inputRef}  inputName={inputName} senden={senden}>
                </OneTextInput>

        </div>
);
})
export default EinfacheEingabeMainComp;