import { createTheme } from '@mui/material'
import { deDE } from '@mui/material/locale'

const colorMidnight = '#003A40'
const colorLagoon = '#00B2A9'
// const colorDarkLagoon = '#008481'
const colorBasil = '#00965E'
// const colorAmarillo = '#F1C400'
const colorDarkAmarillo = '#F0AF00'
const colorDarkRed = '#DE3400'
const colorWhite = '#FFFFFF'

export const textColorsLight = {
  primary: 'rgba(0, 58, 64, 0.87)',
  secondary: 'rgba(0, 58, 64, 0.74)',
  disabled: 'rgba(0, 58, 64, 0.54)',
  hint: 'rgba(0, 58, 64, 0.74)',
}

const darkBlue = {
  main: colorMidnight,
  contrastText: colorWhite,
}

const lagoon = {
  main: colorLagoon,
  contrastText: colorWhite,
  // contrastText: colorMidnight,
}

const yellow = {
  main: colorDarkAmarillo,
  contrastText: textColorsLight.primary,
}

const success = {
  main: colorBasil,
  contrastText: colorWhite,
}

const warning = yellow

const error = {
  main: colorDarkRed,
  contrastText: colorWhite,
}

const typography = {
  fontFamily: [
    'DM Sans',
    'sans-serif',
  ].join(','),
  h1: {
    fontSize: 27,
    fontWeight: 600
  },
  h2: {
    color: colorLagoon,
    fontSize: 24,
    fontWeight: 500
  }
}

export const darkTheme = createTheme({
  // Komm.ONE dunkle Palette
  palette: {
    mode: 'dark',
    primary: lagoon,
    secondary: yellow,
    darkBlue,
    lagoon,
    yellow,
    success,
    warning,
    error,
    background: {
      default: colorMidnight,
      paper: colorMidnight,
      button: colorMidnight
    },
  },
  typography
}, deDE)

export const lightTheme = createTheme({
  // Komm.ONE Palette
  palette: {
    mode: 'light',
    primary: lagoon,
    secondary: yellow,
    darkBlue,
    lagoon,
    yellow,
    success,
    warning,
    error,
    text: textColorsLight,
  },
  typography
}, deDE)


