
import {Grid,Slide, Button, Typography} from "@mui/material";


import React, { useRef, useState,useEffect } from 'react'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'



const Login = ({children}) => {
    const userRef = useRef()
    const errRef = useRef()


    const [loading, setLoading] = useState(false)
    const [loginModalOpen, setLoginModalOpen] = useState(false)

    const registerModal = useRef()
    const resetPasswordModal = useRef()

    const toggleLoginModal = () => {setLoginModalOpen(prevState => !prevState)}
    const openRegisterModal = () => {registerModal.current.handleOpen()}




    const openResetPasswordModal = () => {resetPasswordModal.current.handleOpen()}

    return (
        <>
            <Slide in {...({ timeout: 800 })} direction="down">
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        position: 'absolute',
                        top: 75,
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    <Grid item>
                        <Typography variant="h3" align="center">{children}</Typography>
                    </Grid>
                    <Grid item style={{ margin: 20 }}>
                        <Typography variant="h4" align="center">
                            Bitte loggen Sie sich ein oder registrieren Sie sich.
                        </Typography>
                    </Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        type="button"
                        size="large"
                        variant="contained"
                        style={{ margin: 5 }}
                        onClick={toggleLoginModal}
                    >
                        Anmelden
                    </Button>
                    <Button
                        type="button"
                        size="large"
                        variant="outlined"
                        style={{ margin: 5 }}
                        onClick={() => console.log("register")}
                    >
                        Registrieren
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        style={{ margin: 5, marginTop: 75 }}
                        onClick={() => console.log("reset")}
                    >
                        Passwort vergessen
                    </Button>
                </Grid>
                </Grid>
            </Slide>
            <LoginModal setLoading={setLoading} open={loginModalOpen} toggleModal={toggleLoginModal}/>
            
            
        </>
    );
};

export default Login;