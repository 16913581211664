import React from 'react';
import Box from "@mui/material/Box";
import Bueromaterial_white from "../Resources/img/Bueromaterial_white.png"
import Bueromaterial_midnight from "../Resources/img/Bueromaterial_midnight.png"
import {useTheme} from "@mui/material/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TooltipWrapper from "./TooltipWrapper";
import {IconButton, Typography} from "@mui/material";
import PropTypes from "prop-types";

const Nachricht = ({children, text}) => {
    const theme = useTheme()

    const handleCopyToClipboard= ()=>{
        let newStr = JSON.stringify(text)
        newStr = newStr.slice(1, -1);
        navigator.clipboard.writeText(newStr)

    }
    return (
        <div>
            <Box sx={{display:'flex', flexDirection: 'row', width:"95%", mb:4, whiteSpace:"pre-wrap"}} alignItems={"left"} justifyContent={"flex-start"}>
                <Box component={"img"} src={theme.palette.mode === 'light' ?Bueromaterial_white:Bueromaterial_midnight} sx={{
                    height: 50,
                    mr : 3,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                }}/>

                <Box border={0.5} borderRadius={3}  width={"80%"} padding={3}>
                    <Typography  >
                        {text}
                    </Typography>
                </Box>

                <TooltipWrapper title={"In die Zwischenablage kopieren"}>
                <IconButton
                    sx={{
                        height:40,
                        width:40
                    }}
                onClick={handleCopyToClipboard}
                >
                    <ContentCopyIcon/>
                </IconButton>
                </TooltipWrapper>
            </Box>

        </div>
    );
};


export default Nachricht;

Nachricht.propTypes = {
    text: PropTypes.string

};