import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';
import { MenuItem, Select, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import {RequestContext} from "../../App";


const SocialMediaPostMainComp = forwardRef(({setAdditionalInfo, senden}, ref) => {
    const [info, setInfo] = useState({})
    const [plattform, setPlattform] = useState("")
    const [publikum, setPublikum] = useState("")
    const [thema, setThema] = useState("")
    const [weitereInfo, setWeitereInfo] = useState("")
    const platformOptions = ["Instagram (kurz)", "Instagram (lang)","X(free)","X(paid)", "Facebook","LinkedIn"]
    const requestCon = useContext(RequestContext)


    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: clearInputs,
        getMsgInfo: buildMsgInfo
    }))

    const clearInputs=()=>{
        setPlattform("")
        setPublikum("")
        setThema("")
        setWeitereInfo("")

    }
    const handlePlattformChange =(e)=>{
        setPlattform(e.target.value)
    }

    const buildMsgInfo=()=>{
        requestCon["messages"] = []
        return([])
    }
    const buildAddInfo =()=>{
        requestCon["request"] = {
            "Platform":plattform,
            "Publikum":publikum,
            "Thema":thema,
            "weitereInfos": weitereInfo
        }

        return({
            "Platform":plattform,
            "Publikum":publikum,
            "Thema":thema,
            "weitereInfos": weitereInfo
        })
    }



    useEffect(() => {
        buildAddInfo()
        setAdditionalInfo(buildAddInfo())

    }, [plattform,publikum,thema]);


    const handleKeyUp = (e) =>{
        if (e.key === "Enter") {
            senden();
            e.target.value = ""
        }
    }


    return (
        <Table>
            <TableBody onKeyUp={(e)=>handleKeyUp(e)} >
                <TableRow>
                    <TableCell>
                        <Select
                            sx={{
                                width:"100%"
                            }}
                                labelId="lengthSelect-label"
                                id="Plattform"
                                onChange = {(e) => handlePlattformChange(e)}
                        >
                            {platformOptions.map((text) => (
                                <MenuItem value = {text}> {text}</MenuItem>
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField onChange={(e)=>setPublikum(e.target.value)} id={"smPublikum_textField"}  label={"Publikum"} autoComplete='off'></TextField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <TextField onChange={(e)=>setThema(e.target.value)} id={"smThema_textField"} label={"Thema"} autoComplete='off'></TextField>
                    </TableCell>
                    <TableCell>
                        <TextField onChange={(e)=>setWeitereInfo(e.target.value)} id={"smWeitereInfos_textField"} label={"Weitere Infos"} autoComplete='off'></TextField>
                    </TableCell>
                </TableRow>
            </TableBody>

        </Table>
    );
})

export default SocialMediaPostMainComp;