import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { ExitToApp, Home, DarkMode, LightMode, Menu } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import logo from '../Resources/img/KommONE_NEG_RGB.svg';
import { isLoggedIn, logout } from '../Services/login';
import TooltipWrapper from "./TooltipWrapper";
import {useNavigate} from "react-router-dom";

function CustomAppBar({ toggleTheme, title, toggleSidebar, drawerWidth }) {
    const [loggedIn, setLoggedIn] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();

    // Uncomment the following lines if you want to check the login status on component mount
    useEffect(() => {
        if(window.localStorage.getItem("access_token")){
            setLoggedIn(true)
        }else{
            setLoggedIn(false)
            navigate("/login")
        }
    }, []);

    // isLoggedIn().then(() => setLoggedIn(true)).catch(() => setLoggedIn(false))

    const logoutUser = () => {
        try{
            window.localStorage.removeItem("access_token")
            navigate("/login");
        }catch(error){
            console.log(error)
        }
    };

    const redirectHome = () => {
        window.location.reload();
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar color="darkBlue" position="fixed" sx={{ ml: `${drawerWidth}px`, width: `calc(100% - ${drawerWidth}px)` }}>
                <Toolbar style={{ paddingLeft: 0 }}>
                    <TooltipWrapper title="Home">
                        <IconButton
                            style={{ margin: 5 }}
                            color="inherit"
                            aria-label="Home"
                            onClick={() => toggleSidebar(true)}
                            size="large"
                            edge="start"
                        >
                            <Menu fontSize="large" />
                        </IconButton>
                    </TooltipWrapper>
                    <TooltipWrapper title="Home">
                        <img
                            style={{
                                height: 50,
                                width: undefined,
                                margin: 10,
                            }}
                            alt="Komm.ONE Logo"
                            src={logo}
                        />
                    </TooltipWrapper>
                    {title ? (
                        <>
                            <Typography
                                style={{
                                    marginRight: 20,
                                    marginBottom: 6,
                                }}
                                variant="h1"
                                color="inherit"
                                noWrap
                            >
                                Textschmiede
                            </Typography>
                            <Typography style={{ flexGrow: 1, marginBottom: 6 }} variant="h2" color="inherit" noWrap>
                                {title}
                            </Typography>
                        </>
                    ) : (
                        <Typography
                            style={{
                                marginBottom: 6,
                                flexGrow: 1,
                                textAlign: 'left',
                            }}
                            variant="h1"
                            color="inherit"
                            noWrap
                        >
                            Textschmiede
                        </Typography>
                    )}
                    {/* <TooltipWrapper title="Home">
                       <IconButton
                           style={{ margin: 5 }}
                           color="inherit"
                           aria-label="Home"
                           onClick={redirectHome}
                           size="large"
                       >
                           <Home fontSize="large" />
                       </IconButton>
                   </TooltipWrapper>
                   */}
                    {toggleTheme && (
                        <TooltipWrapper title={`Zum ${theme.palette.mode === 'light' ? 'dunklen' : 'hellen'} Modus wechseln`}>
                            <IconButton
                                style={{ margin: 5 }}
                                color="inherit"
                                aria-label={`Zum ${theme.palette.mode === 'light' ? 'dunklen' : 'hellen'} Modus wechseln`}
                                onClick={toggleTheme}
                                size="large"
                            >
                                {theme.palette.mode === 'light' ? <DarkMode fontSize="large" /> : <LightMode fontSize="large" />}
                            </IconButton>
                        </TooltipWrapper>
                    )}
                    {loggedIn && (
                        <TooltipWrapper title="Logout">
                            <IconButton color="inherit" onClick={logoutUser} size="large">
                                <ExitToApp fontSize="large" />
                            </IconButton>
                        </TooltipWrapper>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

CustomAppBar.propTypes = {
    toggleTheme: PropTypes.func,
    title: PropTypes.string,
    toggleSidebar: PropTypes.func.isRequired,
    drawerWidth: PropTypes.number.isRequired,
};

CustomAppBar.defaultProps = {
    toggleTheme: null,
    title: '',
};

export default CustomAppBar;
