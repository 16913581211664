import React, { useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { useContext } from 'react';
import {RequestContext} from "../App";
import { styled } from '@mui/material/styles';




const FileUpload = ({setFile, acceptType, text, id }) => {
    const requestCon = useContext(RequestContext)
    const [uploadFile, setUploadFile] = useState(null);




    const handleFileChange = (event) => {
        requestCon["docs"]= event.target.files[0]
        setUploadFile(event.target.files[0])
        setFile(event.target.files[0])

    };
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        overflow: 'hidden',
        position: 'absolute'
    });


    return (
            <Button
                component="label"
                role={undefined}
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                border: '2px dashed grey',
                borderRadius: '10px',
                width: '300px',
                textAlign: 'center'

            }}>
                <VisuallyHiddenInput type="file" accept={acceptType} name={"docs"} idText={id} onChange={handleFileChange} />
                <Box>
                            <CloudUpload sx={{ fontSize: 60 }} />
                    <Typography variant="h6" component="div">
                        {uploadFile ? uploadFile.name : text}
                    </Typography>
                </Box>

            </Button>


    );
};

export default FileUpload;