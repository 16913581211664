import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from 'react';
import {FormControlLabel, Table, TableBody, TableCell, TableRow} from "@mui/material";
import AnpassungMainComp from "./AnpassungMainComp";
import Checkbox from "@mui/material/Checkbox";
import OneTextInput from "./OneTextInput";
import {RequestContext} from "../../App";


const AnpassungenDocChat = forwardRef(({inputName, senden}, ref) => {

    const requestCon = useContext(RequestContext)
    const [nachfrage, setNachfrage] = useState(false)
    const [text, setText] = useState("")

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo : buildMsgInfo
    }))

    const buildAddInfo =()=>{
        requestCon["request"] = {
            "Text": inputRef?.current?.getValue(),
            "Nachfrage": document.getElementById("nachfrage_checkbox").value
        }
        return({
            "Text": inputRef?.current?.getValue(),
            "Nachfrage": document.getElementById("nachfrage_checkbox").value
        })
    }



    const buildMsgInfo =()=>{
        requestCon["messages"] = [{
            "role": "user",
            "content": inputRef?.current?.getValue()
        }]
        return( [
            {
                "role": "user",
                "content": inputRef?.current?.getValue()
            }
        ])
    }

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <OneTextInput ref={inputRef} inputName={inputName} senden = {senden} setMessage={setText}>
                            </OneTextInput>                        </TableCell>
                        <TableCell>
                            <FormControlLabel  control={<Checkbox id={"nachfrage_checkbox"} defaultChecked/>} labelPlacement="bottom" label="Nachfrage"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            
        </div>
    );
})

export default AnpassungenDocChat;