import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import Nachricht from "./Nachricht";
import PropTypes from "prop-types";
import RedeMainComp from "./ExampleMainComponents/RedeMainComp";

import {useTheme} from "@mui/material/styles";
import {IconButton, LinearProgress, Table, TableCell, TableRow} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import UserNachricht from "./UserNachricht";
import ZusammenfassungMainComp from "./ExampleMainComponents/ZusammenfassungMainComp";
import EinfacheEingabeMainComp from "./ExampleMainComponents/EinfacheEingabeMainComp";
import AnpassungMainComp from "./ExampleMainComponents/AnpassungMainComp";
import SocialMediaPostMainComp from "./ExampleMainComponents/SocialMediaPostMainComp";
import ChatDocMainComp from "./ExampleMainComponents/ChatDocMainComp";
import Checkbox from "@mui/material/Checkbox";
import AnpassungenDocChat from "./ExampleMainComponents/AnpassungenDocChat";
import {useNavigate} from "react-router-dom";
import TranskriptionMainComp from "./ExampleMainComponents/TranskriptionMainComp";


const MainComp = forwardRef(({setFormData, setNachfrage, setAdditionalInfo, drawerWidth, beispiel, senden, loading, convsById}, ref) => {
    const theme = useTheme()
    const messagesEndRef = useRef(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    const inputRef = useRef()


    useEffect(() => {
        if(window.localStorage.getItem("access_token")){
            setLoggedIn(true)
        }else{
            setLoggedIn(false)
            navigate("/login")
        }
    }, []);

    useEffect(() => {

        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [convsById]);

    const logoutUser = () => {
        try{
            window.localStorage.removeItem("access_token")
            navigate("/login");
        }catch(error){
            console.log(error)
        }
    };

    const redirectHome = () => {
        window.location.reload();
    };


    useImperativeHandle(ref, () => ({
        getAddInfo: inputRef?.current?.getAddInfo,
        clearInput: inputRef?.current?.clearInput,
        getMsgInfo: inputRef?.current?.getMsgInfo
    }))


        return (
        <Box display="flex" justifyContent="center">
            <Box
                id={"main_messagesWindow"}
                width={`calc(95% - ${drawerWidth}px)`}
                ml={`${drawerWidth}px`}
            >
                <Box
                    flexGrow={1}
                    overflow="auto"
                    p={2}
                    sx={{
                        width: "90%",
                        mb: 30
                    }}
                    ref={messagesEndRef}
                >

                    {convsById.map((msg, index) => (

                        msg.role === "user" ?
                            <UserNachricht key={index} mb={12} text={msg.message}></UserNachricht> :
                            <Nachricht key={index} mb={12} text={msg.message}></Nachricht>
                    ))}

                </Box>
                <div ref={messagesEndRef}/>
                <Box component={"footer"} sx={{
                    zIndex: 5,
                    width: `calc(100% - ${drawerWidth}px)`,
                    position: "fixed",
                    bottom: 0,
                    left: `${drawerWidth}px`,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    bgcolor: theme.palette.mode === 'light' ? "white" : "#003A40",
                    p: 1,
                }}>
                    <Box
                        width={"95%"}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            p: 1,
                            m: 1,
                            bgcolor: 'background.paper',
                        }}
                    >

                        <Box width={"95%"}>
                            {loading && <LinearProgress style={{marginBottom: 10}}/>}
                            {convsById.length >= 1 && beispiel !== "Doc Q&A" &&
                                <AnpassungMainComp ref={inputRef} inputName={"Anpassungen Prompt"}
                                                   senden={senden}></AnpassungMainComp>}
                            {convsById.length >= 1 && beispiel === "Doc Q&A" &&
                                <AnpassungenDocChat ref={inputRef} inputName={"Anpassungen Prompt"}
                                                    senden={senden}></AnpassungenDocChat>}
                            {convsById.length === 0 && beispiel === "Zusammenfassung" &&
                                <ZusammenfassungMainComp ref={inputRef} setAdditionalInfo={setAdditionalInfo}
                                                         senden={senden} inputName={"Text zum Zusammenfassen"}/>}
                            {convsById.length === 0 && beispiel === "Einfache Sprache" &&
                                <EinfacheEingabeMainComp ref={inputRef} setAdditionalInfo={setAdditionalInfo}
                                                         senden={senden} inputName={"Text für Einfache Sprache"}/>}
                            {convsById.length === 0 && beispiel === "Chat" &&
                                <EinfacheEingabeMainComp ref={inputRef} setAdditionalInfo={setAdditionalInfo}
                                                         senden={senden} inputName={"Chateingabe"}/>}
                            {convsById.length === 0 && beispiel === "Rede" &&
                                <RedeMainComp ref={inputRef} senden={senden} setAdditionalInfo={setAdditionalInfo}/>}
                            {convsById.length === 0 && beispiel === "Social Media Post" &&
                                <SocialMediaPostMainComp ref={inputRef} senden={senden}
                                                         setAdditionalInfo={setAdditionalInfo}/>}
                            {convsById.length === 0 && beispiel === "Doc Q&A" &&
                                <ChatDocMainComp ref={inputRef} setFormData={setFormData}
                                                 senden={senden}></ChatDocMainComp>}
                            {convsById.length === 0 && beispiel === "Transkription" &&
                                <TranskriptionMainComp ref={inputRef}
                                                       setFormData={setFormData} senden={senden}></TranskriptionMainComp>}
                        </Box>
                        <IconButton
                            sx={{mr: 0, width: 50, height: 50}}
                            onClick={senden}>
                            <SendIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
        );
})

export default MainComp;

MainComp.propTypes = {
    messages: PropTypes.array
};
