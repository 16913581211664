import * as React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, Drawer, Box, Divider } from "@mui/material";
import SidebarMainSettingsList from './SidebarMainSettingsList';
import SidebarDetailSettings from './SidebarDetailSettings';
import { useEffect, useRef } from "react";

export default function Sidebar({ setconvId, setMessages, conversations, model, setModel, toggleDrawer, open, drawerWidth, beispiel, handleBeispielChange, newConv }) {
    const sidebarRef = useRef(null);

    const handleModelChange = (event) => {
        setModel(event.target.value);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            toggleDrawer(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        //return () => {
        //    document.removeEventListener('mousedown', handleClickOutside);
        //};
    }, [open]);

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            open={open}
        >
            <Box ref={sidebarRef} sx={{ width: '100%', height: '100%' }}>
                <Box sx={{ justifyContent: 'flex-end', display: "flex" }}>
                    <IconButton onClick={() => toggleDrawer(false)} sx={{ mr: 0, width: 50, height: 50 }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>

                <Divider />
                <SidebarMainSettingsList model={model} handleModelChange={handleModelChange} />
                <Divider />
                <SidebarDetailSettings setConvId={setconvId} setMessages={setMessages} conversations={conversations} newConv={newConv} example={beispiel} handleExampleChange={handleBeispielChange} />
            </Box>
        </Drawer>
    );
}
