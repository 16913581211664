import PropTypes from "prop-types"
import React from "react"
import { Tooltip } from "@mui/material"

const TooltipWrapper = ({children, title, placement, disabled}) => disabled ? children : <Tooltip title={title} placement={placement}>{children}</Tooltip>

TooltipWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(
            PropTypes.element,
        ),
    ]).isRequired,
    title: PropTypes.string.isRequired,
    placement: PropTypes.string,
    disabled: PropTypes.bool,
}

TooltipWrapper.defaultProps = {
    placement: "bottom",
    disabled: false,
}

export default TooltipWrapper
