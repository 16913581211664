import { Button, Divider, FormControl, Grid, Grow, Modal, Paper, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, {createContext, useContext, useEffect, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../../Services/login'
import{ AuthService} from "../../Services/authentication"
import {api} from "./AxiosInterceptor";
import AlertDialog from "../../Components/AlertDialog";


function LoginModal(props) {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [alertContent, setAlertContent] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertAccept, setAlertAccept] = useState(null)
  const navigate = useNavigate()

  const handleClose = () => {props.toggleModal()}

  const handleUsernameChange = (e) => {setUsername(e.target.value)}

  const handlePasswortChange = (e) => {setPassword(e.target.value)}

  const toggleAlert = (title, content, onAccept) => {
   	    if (title != null) {
    	      setAlertTitle(title)
    	      setAlertContent(content)
    	      setAlertAccept(onAccept)
    	    }
   	    setAlertOpen(!alertOpen)
   	  }




  const doLogin = async (e) => {
    e.preventDefault()
    window.localStorage.removeItem("access_token")

    props.setLoading(true)
    console.log(username, password)

    api.post("/token",{"username": username, "password": password}).then(({data}) => {
      window.localStorage.setItem("access_token", data.access_token)
      console.log(data)
    }).then((data)=>{
      navigate("/")
        }).catch((error) => {
      console.log(error)
      toggleAlert('Anmeldung fehlgeschlagen', 'Sie konnten nicht angemeldet werden. Bitte überprüfen Sie die eingegebene Benutzerkennung und das Passwort.', null)

    });
  }
  return (
    <div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={props.open}
        onClose={handleClose}
        style={{ padding: '100px 0 20px' }}
      >
        <Grow in={props.open}>
          <Paper
            style={{
              width: '400px',
              maxHeight: '100%',
              overflow: 'auto',
              margin: 'auto',
              padding: 20,
            }}
          >
            <div>
              <Typography variant="h6">
                Login
              </Typography>
              <Typography variant="body1">
                Bitte geben Sie Ihre Benutzernamen und Ihr Passwort ein, um sich anzumelden.
              </Typography>
              <Typography variant="body2" sx={{ my: 1 }}>
                Wenn Sie bei der Registrierung keinen Benutzernamen festgelegt haben, verwenden Sie bitte Ihre
                E-Mail-Adresse.
              </Typography>
              <Divider/>
              <FormControl style={{ width: '100%' }}>
                <form
                onSubmit={doLogin}
                >
                  <TextField
                    style={{ width: '100%' }}
                    variant="standard"
                    name="username"
                    aria-label="Benutzername"
                    label="Benutzername"
                    value={username}
                    onChange={handleUsernameChange}
                    required
                  />
                  <TextField
                    type="password"
                    style={{ width: '100%' }}
                    variant="standard"
                    name="password"
                    aria-label="Passwort"
                    label="Passwort"
                    value={password}
                    onChange={handlePasswortChange}
                    required
                  />
                  <Grid
                    container
                    style={{ marginTop: 20 }}
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    direction="row"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                    >
                      Anmelden
                    </Button>

                  </Grid>
                </form>
              </FormControl>
            </div>
          </Paper>
        </Grow>
      </Modal>
      <AlertDialog
        title={alertTitle}
        content={alertContent}
        open={alertOpen}
        onAccept={alertAccept}
        toggle={((title, content, onAccept) => toggleAlert(title, content, onAccept))}
      />
    </div>
  )
}

export default LoginModal
