// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Resources/fonts/dm-sans-v4-latin-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Resources/fonts/Roboto-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "DM Sans";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
}

@font-face {
    font-family: "Roboto";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
}

a {
    text-decoration: none !important;
}

h1 {
    font-size: 10px;
}

.MuiTextField-root{

    width: 100%;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4DAA0E;AAC9E;;AAEA;IACI,qBAAqB;IACrB,4DAAgE;AACpE;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI,WAAW;AACf","sourcesContent":["@font-face {\r\n    font-family: \"DM Sans\";\r\n    src: url('Resources/fonts/dm-sans-v4-latin-regular.woff2') format('woff2');\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Roboto\";\r\n    src: url('Resources/fonts/Roboto-Regular.woff2') format('woff2');\r\n}\r\n\r\na {\r\n    text-decoration: none !important;\r\n}\r\n\r\nh1 {\r\n    font-size: 10px;\r\n}\r\n\r\n.MuiTextField-root{\r\n\r\n    width: 100%;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
