import React from 'react';
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TooltipWrapper from "./TooltipWrapper";
import {IconButton, Typography} from "@mui/material";
import PropTypes from "prop-types";
import PersonIcon from '@mui/icons-material/Person';

const UserNachricht = ({children, text}) => {
    const theme = useTheme()

    const handleCopyToClipboard= ()=>{
        let newStr = JSON.stringify(text)
        newStr = newStr.slice(1, -1);
        navigator.clipboard.writeText(newStr)

    }
    return (
        <div>
            <Box sx={{display:'flex', flexDirection: 'row-reverse', width:"95%", mb:4, whiteSpace:"pre-wrap"}} justifyContent={"flex-start"} >
                <PersonIcon fontSize="large"/>
                <Box border={0.5} borderRadius={3}  width={"80%"} padding={3}>
                    <Typography  >
                        {text}
                    </Typography>
                </Box>
                <TooltipWrapper title={"In die Zwischenablage kopieren"}>
                    <IconButton
                        sx={{
                            height:40,
                            width:40
                        }}
                        onClick={handleCopyToClipboard}
                    >
                        <ContentCopyIcon/>
                    </IconButton>
                </TooltipWrapper>
            </Box>

        </div>
    );
};


export default UserNachricht;

UserNachricht.propTypes = {
    text: PropTypes.string

};