// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxedGridItem{
    margin: 1em;

}

.rightButton{
    float: right;
}

#main_messagesWindow{
    height: 100%;
    margin-top: 5em;
    display : flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.messages{
    border: 0.5px;
    border-radius: 5px;
    width: "85%";

}`, "",{"version":3,"sources":["webpack://./src/Styles/HomeTextschmied.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,cAAc;IACd,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;;AAEhB","sourcesContent":[".boxedGridItem{\r\n    margin: 1em;\r\n\r\n}\r\n\r\n.rightButton{\r\n    float: right;\r\n}\r\n\r\n#main_messagesWindow{\r\n    height: 100%;\r\n    margin-top: 5em;\r\n    display : flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    overflow: auto;\r\n}\r\n\r\n.messages{\r\n    border: 0.5px;\r\n    border-radius: 5px;\r\n    width: \"85%\";\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
