
import { Button, Divider, FormControl, Grid, Grow, Modal, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function RegisterModal() {
  
  return (
    <div>
            <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"

        
        style={{ padding: '100px 0 20px' }}
      >
        <Grow >
          <Paper
            style={{
              width: '400px',
              maxHeight: '100%',
              overflow: 'auto',
              margin: 'auto',
              padding: 20,
            }}
          >
            <div>
              <Typography variant="h6">
                Registrierung
              </Typography>
              <Typography variant="body1">
               Bitte füllen Sie die folgenden Felder um sich zu registrieren.
              </Typography>
              <Typography variant="body2" sx={{ my: 1 }}>
                Die Eingabe eines Benutzernamen ist nur dann notwendig, wenn Sie mehrere Benutzer mit Ihrer
                E-Mail-Adresse anlegen. Ansonsten entspricht Ihr Benutzername Ihrer E-Mail-Adresse.
              </Typography>
              <Divider/>
              <FormControl style={{ width: '100%' }}>
                <form >
                  <TextField
                    style={{ width: '100%' }}
                    variant="standard"
                    name="username"
                    aria-label="Benutzername"
                    label="Benutzername"
                    value="Admin"
                    
                  />
                  <TextField
                    type="password"
                    style={{ width: '100%' }}
                    variant="standard"
                    name="password"
                    aria-label="Passwort"
                    label="Passwort"
                    value="Passwort"
                    
                  />
                  <Grid
                    container
                    style={{ marginTop: 20 }}
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    direction="row"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                    >
                      Anmelden
                    </Button>
                  </Grid>
                </form>
              </FormControl>
            </div>
          </Paper>
        </Grow>
      </Modal>
    </div>
  )
}

export default RegisterModal