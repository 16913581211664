import {useEffect} from 'react'
import axios from 'axios'
import {useNavigate} from "react-router-dom";

// export const server = 'https://sproudy.de'

export const server = 'https://textschmiede.kommhub.de/api/v1'

export const baseURL = `${server}`

export const api = axios.create({baseURL})


const AxiosInterceptor = ({children}) => {
    const navigate = useNavigate()

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(
            async config => {
                const accessToken = window.localStorage.getItem('access_token')
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`
                }
                return config
            },
            error => Promise.reject(error),
        )

        const responseInterceptor = api.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config

                // If the error status is 401 and there is no originalRequest._retry flag,
                // it means the token has expired and we need to refresh it
                //if (error?.response?.status === 401 && !originalRequest._retry) {
                   // originalRequest._retry = true

                if(error?.response?.status === 401){
                    window.localStorage.removeItem('access_token')
                    navigate("/login")


//To Do: refreshToken Einstellen
//try {
//    const refreshToken = window.localStorage.getItem('refresh_token')
//   // const response = await axios.post(
//   //     `${baseURL}/public/refreshToken`,
//   //     {refreshToken}
//   // )
//    const response = await axios.post(
//        `${baseURL}/token`,
//    {"username": "johndoe", "password":"secret"}
//    )


//    //const {accessToken, newRefreshToken} = response.data
//    const {accessToken} = response.data
//    window.localStorage.setItem('access_token', accessToken)
//    //window.localStorage.setItem('refresh_token', newRefreshToken)

//    // Retry the original request with the new token
//    originalRequest.headers.Authorization = `Bearer ${accessToken}`
//    return axios(originalRequest)
//} catch (e) {
//    // Handle refresh token error or redirect to login
//    console.error('Error at refresh token', e?.response?.status)

//    //If refresh token is invalid, you will receive this error status and log user out
//    if (e?.response?.status === 400) {
//        throw {response: {status: 401}}
//    }
//    window.localStorage.removeItem('access_token')
//    //window.localStorage.removeItem('refresh_token')
//    return Promise.reject(e)
//}
                }
                return Promise.reject(error)
            },
        )

        return () => {
            axios.interceptors.request.eject(requestInterceptor)
            axios.interceptors.response.eject(responseInterceptor)
        }
    })

    return children
}

export default AxiosInterceptor
