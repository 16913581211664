import React, {createContext, useState} from 'react'
import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material'
import {lightTheme, darkTheme} from "./Services/theme"
import HomeTextschmied from './Scenes/Sites/HomeTextschmied'
import './App.css'
import AxiosInterceptor from "./Scenes/Login/AxiosInterceptor";
import {BrowserRouter as Router, Route, Switch, Link, Routes, BrowserRouter} from 'react-router-dom';
import Login from "./Scenes/Login/Login";
import Missing from "./Scenes/Sites/Missing";
import ProtectedRoute from "./Components/ProtectedRoute";
export const RequestContext = createContext();
const App = () => {
    let infos = {
        request: null,
        data:null,
        messages: []
    }


    const [loggedIn, setLoggedIn] = useState(false)
    const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches
    const getDefaultMode = () => {
        const localStorageTheme = localStorage.getItem('default-theme')
        const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light'
        return localStorageTheme || browserDefault

    }

    const [mode, setMode] = useState(getDefaultMode())

    const setTheme = (theme) => {
        setMode(theme)
        localStorage.setItem('default-theme', theme)
    }

    const toggleTheme = () => {
        mode === 'light' ? setTheme('dark') : setTheme('light')
    }

    return (
        <RequestContext.Provider value={infos}>
        <AxiosInterceptor>
                <div className="App">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
                            <CssBaseline/>
                                <Routes>
                                    <Route path = "/login" element={<Login/>}/>

                                    {/*make protected */}

                                    <Route path = "/" element={
                                        <ProtectedRoute>
                                            <HomeTextschmied toggleTheme={toggleTheme}/>
                                        </ProtectedRoute>
                                    }>
                                    </Route>

                                    <Route path={"*"} element={<Missing/>}/>

                                </Routes>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
        </AxiosInterceptor>
        </RequestContext.Provider>
    )
}

export default App;