import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Box, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import TooltipWrapper from "../TooltipWrapper";
import {RequestContext} from "../../App";

const RedeMainComp = forwardRef(({setAdditionalInfo, senden}, ref) => {
    const theme = useTheme()
    const [info, setInfo] = useState({})
    const [rednerrolle, setRednerrolle] = useState("")
    const [anlass, setAnlass] = useState("")
    const [emotion, setEmotion] = useState("")
    const [publikum, setPublikum] = useState("")
    const [thema, setThema] = useState("")
    const [weitereInhalte, setWeitereInhalte] = useState("")
    const [dauer, setDauer] = useState("")
    const requestCon = useContext(RequestContext)


    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: clearInputs,
        getMsgInfo: buildMsgInfo
    }))

    const clearInputs=()=>{
        setRednerrolle("")
        setAnlass("")
        setEmotion("")
        setPublikum("")
        setThema("")
        setWeitereInhalte("")
        setDauer("")
    }

    useEffect(() => {
        buildAddInfo()
        setAdditionalInfo(buildAddInfo())
    }, [rednerrolle,anlass,emotion,publikum,thema,weitereInhalte,dauer]);


    const buildAddInfo =()=>{
        requestCon["request"] = {
            "Rednerrolle":rednerrolle,
            "Anlass":anlass,
            "Emotion":emotion,
            "Publikum":publikum,
            "Thema":thema,
            "weitere_Inhalte":weitereInhalte,
            "Dauer":dauer
        }

        return({
            "Rednerrolle":rednerrolle,
            "Anlass":anlass,
            "Emotion":emotion,
            "Publikum":publikum,
            "Thema":thema,
            "weitere_Inhalte":weitereInhalte,
            "Dauer":dauer
        })
    }

    const handleKeyUp = (e) =>{
        if (e.key === "Enter") {
            senden();
            e.target.value = ""
        }
    }
    const buildMsgInfo=()=>{
        requestCon["messages"] = []
        return([])
    }

    return (
                <Table>
                    <TableBody onKeyUp={(e)=>handleKeyUp(e)} >
                        <TableRow>
                            <TableCell>
                                <TextField onChange={(e)=>setRednerrolle(e.target.value)} id={"redeRednerrolle_textField"} label={"Rednerrolle"}></TextField>
                            </TableCell>
                            <TableCell>
                                <TextField onChange={(e)=>setAnlass(e.target.value)} id={"redeAnlass_textField"}  label={"Anlass"}></TextField>
                            </TableCell>
                            <TableCell>
                                <TextField onChange={(e)=>setEmotion(e.target.value)} id={"redeEmotion_textField"} label={"Emotion"}></TextField>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField onChange={(e)=>setPublikum(e.target.value)} id={"redePublikum_textField"}  label={"Publikum"}></TextField>
                            </TableCell>
                            <TableCell>
                                <TextField onChange={(e)=>setThema(e.target.value)} id={"redeThema_textField"} label={"Thema"}></TextField>
                            </TableCell>
                            <TooltipWrapper title={"Sprachmodelle haben oft Probleme mit Längeneinschätzungen. Es kann sein, dass die Länge nicht richtig generiert wird."}>
                            <TableCell>

                                <TextField onChange={(e)=>setDauer(e.target.value)} id={"redeDauer_textField"} label={"Dauer"}></TextField>
                            </TableCell>
                            </TooltipWrapper>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField onChange={(e)=>setWeitereInhalte(e.target.value)} id={"redeWeitereInhalte_textField"} label={"Weitere Inhalte"}></TextField>
                            </TableCell>
                        </TableRow>

                    </TableBody>

                </Table>
    );
})
export default RedeMainComp;