import {CssBaseline} from "@mui/material";
import Sidebar from "../../Components/Sidebar";
import * as React from "react";
import { useContext, useEffect, useRef, useState} from "react";
import "../../Styles/HomeTextschmied.css"
import CustomAppBar from "../../Components/CustomAppBar";
import MainComp from "../../Components/MainComp";
import {api} from "../Login/AxiosInterceptor";
import {RequestContext} from "../../App";




const HomeTextschmied = ({toggleTheme}) => {

    const [model, setModel] = useState("OpenSource")
    const [beispiel, setBeispiel] = useState("Zusammenfassung")
    const [message, setMessage] = useState("")
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [drawerActWidth, setDrawerActWidth] = useState(0)
    const [loading, setLoading] = useState(false)
    const [messages, setMessages] = useState([]);
    const [task, setTask] = useState("summary")
    const [conversations, setConversations] = useState([])
    const [conversationsById, setConversationsById] = useState([])
    const [convId, setconvId] = useState(null)
    const [additionalInf, setAdditionalInf] = useState({})
    const [nachfrage, setNachfrage] = useState([])
    const [formData, setFormData] = useState()

    const mainRef = useRef()
    const requestCon = useContext(RequestContext)


    let bspDict = {
        Zusammenfassung: "summary",
    }
    bspDict["Einfache Sprache"] = "plain_speech"
    bspDict["Rede"] = "speech"
    bspDict["Chat"] = "chat"
    bspDict["text2json"] = "summary"
    bspDict["Spracherkennung"] = "summary"
    bspDict["Social Media Post"] = "social_media_post"
    bspDict["Doc Q&A"] = "document_q_and_a"
    bspDict["Transkription"] = "transcription"

    const drawerWidth = 500

    useEffect(() => {
        setTask(bspDict[beispiel])
        newConv()

    }, [beispiel]);

    useEffect(() => {
        callConversations()
    }, [task]);


    useEffect(() => {
        if(task === "transcription"){
            callConversations(0)
        }else{
            callConversations()
        }

    }, [loading]);

    const toggleDrawer = (newOpen) => {
        setSidebarOpen(newOpen)
        newOpen === true ? setDrawerActWidth(drawerWidth) : setDrawerActWidth(0)
    }
    const handleBeispielChange = (bsp) => {
        setBeispiel(bsp)
    }

    const getMessagesByConversationId = (id = convId) => {

        if (convId === null) {
            return []
        }
        return conversations.flat().filter(item => item.conversation_id === id);
    };

    const newConv = () => {
        setMessages([])
        setMessage("")
        setconvId(null)
        setConversationsById([])
    }

    const callConversations = async (skipMsg=2) => {
        setMessages([])

        api.get(`/conversation_history/${task}?skip_messages=${skipMsg}`).then(({data}) => {
            console.log("converations")
            console.log(data)
            console.log(task)
            setConversations(data)
        }).catch((error) => {
            setMessages([])
            console.log(error)
        });
    }


    const getBuildRequest = () =>

        ({
            "messages": mainRef?.current.getMsgInfo(),
            "additional_data": mainRef?.current?.getAddInfo(),
            "model": {
                "provider": model,
                "modelname": null
            },
            "conversation_id": convId,
            "task": task
        })

    const senden = (e) => {
        //mainRef?.current?.clearInput()
        setLoading(true)
        console.log(task)
        try{
            const buildRequest = getBuildRequest()
            if(task === "document_q_and_a" || task === "transcription"){
                callDocFormdata(buildRequest)

            }else{
                console.log("Call Task")
                callTask(buildRequest)
            }

            getMessagesByConversationId(convId)
            callConversations()

            console.log(convId)
            console.log(messages)
        } catch(e) {
            console.error(e);
            setLoading(false)
        }

    }

    const callDocFormdata =async (buildRequest)=>{
        console.log("FormData call")
        buildRequest= {
            "messages":  requestCon["messages"],
            "additional_data": requestCon["request"],
            "model": {
                "provider": model,
                "modelname": null
            },
            "conversation_id": convId,
            "task": task
        }
        console.log(JSON.stringify(buildRequest))
        const formDataTest = new FormData()
        formDataTest.append("docs", requestCon["docs"])
        formDataTest.append("file", requestCon["docs"])
        formDataTest.append("data", JSON.stringify(buildRequest))

        console.log("ANFRAGE:")
        console.log([...formDataTest])

        api.post(`/${task}`, formDataTest, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({data}) => {
                console.log(data)
                setLoading(false)
                setconvId(data.conv_id)
                setNachfrage([])
            }).catch(error => {
                console.error(`There was an error: ${error}`);
                setLoading(false)
            });
    }
    const callTask = async (buildRequest) => {

        console.log(buildRequest)
        api.post(`/${task}`, buildRequest).then(({data}) => {
            setLoading(false)
            setconvId(data.conv_id)
            setNachfrage([])
        }).catch((error) => {
            console.log(error)
            setLoading(false)

        });
    }


    return (

            <div>
                <CssBaseline/>
                <CustomAppBar toggleTheme={toggleTheme} toggleSidebar={toggleDrawer} drawerWidth={drawerActWidth}/>
                <Sidebar setconvId={setconvId} setMessages={setMessages} newConv={newConv} conversations={conversations}
                         model={model} setModel={setModel} beispiel={beispiel}
                         handleBeispielChange={handleBeispielChange} toggleDrawer={toggleDrawer} open={sidebarOpen}
                         drawerWidth={drawerWidth} />
                <MainComp
                    ref={mainRef}
                    conversations={conversations} convsById={getMessagesByConversationId(convId)}
                    setNachfrage={setNachfrage} setAdditionalInfo={setAdditionalInf} messages={messages}
                    loading={loading} senden={senden} setMessage={setMessage} beispiel={beispiel} drawerWidth={drawerActWidth} setFormData={setFormData}/>
            </div>
            );

};

export default HomeTextschmied;